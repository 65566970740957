import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SectionWrap from "../../../components/sectionWrap"
import Introtext from "../../../components/introtext"
import Headline from "../../../components/headline"
import * as ffsStyles from "../../../components/sectionWrap/sectionWrap.module.css"
import ContentmoduleAbs from "../../../components/contentmoduleAbs"
import {AnchorLink} from "gatsby-plugin-anchor-links"
// Background-Images
import firstBackgroundImage from "../../../images/greyback.jpg"
import secondBackgroundImage from "../../../images/whiteback.jpg"
// Objeckt-Images
import firstObjectImage from "../../../images/projekte/bje-mybuschjaeger/landing/mybje-landing.png"
import secondObjectImage from "../../../images/projekte/abb-mybuildings/landing/abb-be-landing.png"

const EcoSystem = function (props, ref) {
  var counter = 0
  const data = [
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "myBUSCH-JAEGER",
      text: "Digitale Mehrwerte für ein intelligentes Zuhause wie z.B. ein Fernzugriff auf Smart Home oder Türkommunikationsanlagen.",
      slug: "/projekte/ecosystems/mybusch-jaeger-portal",
      objectImage: firstObjectImage,
      bgImage: firstBackgroundImage,
    },
    {
      logo: "/logos/logo-abb.svg",
      titel: "ABB Ability™ Building Ecosystem",
      text: "Plattform für digitale Mehrwertlösungen zur intelligenten Gebäudesteuerung.",
      slug: "/projekte/ecosystems/abb-buildings-ecosystem",
      objectImage: secondObjectImage,
      bgImage: secondBackgroundImage,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Ecosystems"
        description="Tools für Ihre digitale Infrastruktur von Q:marketing"
      />

      <div className="page-container-offset">
        <div className="container-fluid">
          <SectionWrap id="abc" bgCol={"#fff"} className="height50 my-5">
            <div className={`row ${ffsStyles.vcenterRowContent}`}>
              {/* left */}
              <div className={`col-12 col-md-8 px10`}>
                <Headline
                  titel="Ecosystems"
                  showCta={false}
                  skyhead={false}
                  text={<>Wir unterstützen Sie bei der Entwicklung von Strategien und Werkzeugen zur Vernetzung Ihrer Produkte und zur Entwicklung digitaler Mehrwerte.<p className="pt-2"><AnchorLink to="/projekte/ecosystems/#beispiele" title="↓ Beispiele" className="" /></p></>}
                />
              </div>
            </div>
          </SectionWrap>
        </div>

        <div id="beispiele"></div>        

        {data.map(e => {
          counter++
          var result = counter % 2 ? true : false

          return (
            <div className="container-fluid">
              <SectionWrap bgImg={e.bgImage} bgCol={"#fff"} className="height80 projektdetailkachel">
                <div className={`row ${ffsStyles.vcenterRowContent} bg-q-grey-mobile`}>
                  <div className={`col-12 mx-0 px-0`}>
                    {/* Vordergrund */}
                    <ContentmoduleAbs>
                      <div className={`row ${ffsStyles.vcenterRowContent}`}>
                        <div className={`col-md-6 mx-0 px-0 vorschaubild ${result ? "order-md-last" : ""}`}>
                          <img src={e.logo} className="d-md-none xyz logopic" />
                          <Link to={e.slug}><img src={e.objectImage} style={{ objectFit: "contain", pointerEvents: "visible" }} /></Link>
                        </div>
                        <div className={`col-md-6 mx-0 px-0 textpart ${result ? "order-md-first" : ""}`}>
                          <Introtext
                            skypic={e.logo}
                            skyhead={false}
                            titel={e.titel}
                            text={e.text}
                            linklabel="Mehr erfahren"
                            link={e.slug}
                          />
                        </div>
                      </div>
                    </ContentmoduleAbs>
                  </div>
                </div>
              </SectionWrap>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default EcoSystem
